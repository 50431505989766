.hm-2x {
  font-size: 2em;
}
.hm-3x {
  font-size: 3em;
}
.hm-4x {
  font-size: 4em;
}
.hm-5x {
  font-size: 5em;
}
.hm-6x {
  font-size: 6em;
}
.hm-7x {
  font-size: 7em;
}
.hm-8x {
  font-size: 8em;
}
