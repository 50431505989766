body {
  font-family: "Didact Gothic",sans-serif;
  color: $brand-secondary-light
}

h1, h2 {
  padding-bottom: 2px;
  margin-bottom: $grid-gutter-width/2;
  border-bottom: 2px solid $primary;
}
h1,h2,h3,h4,h5 {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
}

.bg-dark a {
  color: $white;
}
.bg-dark a:hover {
  color: $primary;
}
.bg-dark a.btn:hover {
  color: $white;
}
.vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 200px rgba(0,0,0,0.2) inset;
}
.moodBackground:before {
  content: "";
  background-color: $secondary;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.moodBackground {
  position: relative;
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.2) inset, 0px -10px 10px 0px rgba(0,0,0,0.2) inset;
}
.moodBackground:after {
  content: "";
  background: url(img/hole-texture.jpg);
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.moodContent {
  opacity: 1;
  color: $white;

}
.square {
  padding-bottom: 100%;
  position: relative;
  display: block;
}
.square-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.square-content > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.square-content > div > .fa {
  font-size: 10vw;
}

.teaserWidget {
  box-shadow: 0 14px 28px rgba(0,0,0,0.50), 0 10px 10px rgba(0,0,0,0.40);
}

.widget-head {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  min-height: 1px;
  border-bottom: 1px solid $secondary;
}
a.widget-head {
  text-decoration: none;
}
.widget-content {
  position: relative;
  width: 100%;
}

.widget.small .widget-cl {
  flex: 0 0 33%;
  max-width: 33%;
  position: relative;
  width: 100%;
  min-height: 1px;
}
.widget.small .widget-cr {
  flex: 0 0 67%;
  max-width: 67%;
  position: relative;
  width: 100%;
  min-height: 1px;
}
.widget-cl {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
}
.widget-cr {
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
}

.widget-cr h2 {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.2rem;
  line-height: 1.4rem;
}

.widget, .widget-border {
  border: 1px solid $secondary;
}
.widget img {
  width: 100%;
  height: 100%;
}
a.nodecoration {
  text-decoration: none;
}
.widget  .tuneinIndicator {
  display: none;
}
.widget:hover .tuneinIndicator {
  display: block;
  transition: display 1s;
}

.widget .bg-dark * {
  font-color: $white;
}
.widget.small {

}
.widget.small .square-content > div > .fa {
  font-size: 20vw;
}

.newswidget {
  border: 1px solid $secondary;
}
.newswidget .square-content > div > .fa {
  font-size: 6vw;
}
.newswidget .square-content img {
  width: 100%;
  height: 100%;
}

.card-body img {
  max-width: 100%;
}

.tuneinIndicator {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.tuneinIndicator .bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: $secondary;
  opacity: 0.5;
}
.tuneinIndicator .vg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.tuneinIndicator .vg svg {
  max-width: 96px;
  max-height: 96px;
}
.badge-bottom {
  background-color: $primary;
  color: $white;
  padding: $grid-gutter-width/4;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: $grid-gutter-width/4;
}
.nav-link {
  text-transform: uppercase;
  color: $gray-400;
}
.navbar-light .navbar-nav .nav-link.btn {
  color: $gray-400;
}

#content {
  min-height: 1024px;
}
footer {
  min-height: 800px;
}
