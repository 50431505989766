@media screen and (max-width: 768px) {
    #jsPlayer .mobile {
      display: block;
    }
    body.playing {
      overflow: hidden;
    }
    @media all and (orientation: landscape) {
      #jsPlayer .mobile .rotating-stage {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
    #jsPlayer .desktop {
      display: none;
    }
}
@media screen and (min-width: 769px) {
    #jsPlayer .mobile {
      display: none;
    }
    #jsPlayer .desktop {
      display: block;
    }
}

#jsPlayer .jsPlayerControl:hover {
  color: $secondary;
}

@keyframes aloading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
#jsPlayer .jsLoading {
  animation-name: aloading;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#jsPlayer .jsShowProgress {
  cursor: pointer;
  position: relative;
  height: 150px;
}
#jsPlayer .jsShowProgress img {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
#jsPlayer .jsProgressMeter {
  width: 10%;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
#jsPlayer .jsWaveform {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
}
#jsPlayer .jsTimeCurrent {
  display: inline;
  position: absolute;
  left: 0;
  bottom: 0;
}
#jsPlayer .jsTimeTotal {
  display: inline;
  position: absolute;
  right: 0;
  bottom: 0;
}
