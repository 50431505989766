/* === Didact Gothic - regular */
@font-face {
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('Didact Gothic'),
  url("/public/dist/font/DidactGothic-Regular.woff2") format("woff2");
}


// bootstrap settings
$primary: #663535;
$secondary: #343a40;

$navbar-light-active-color: $primary;
$navbar-light-hover-color: $primary;

$brand-primary:         #663535;
$brand-secondary-light: #343a40;

$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          true !default;

$btn-primary-color:           $secondary;
$btn-primary-bg:              $primary;
$btn-primary-border:          $primary;

$font-size-base:              1rem !default;
$font-family-sans-serif:      "Didact Gothic", sans-serif !default;
$font-family-monospace:       "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;

$h1-font-size:                $font-size-base * 1.5 !default;
$h2-font-size:                $font-size-base * 1.4 !default;
$h3-font-size:                $font-size-base * 1.3 !default;
$h4-font-size:                $font-size-base * 1.2 !default;
$h5-font-size:                $font-size-base * 1.1 !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1340px
) !default;

$utilities: (
        "width": (
                property: width,
                class: w,
                values: (
                        1: 1%,
                        5: 5%,
                        10: 10%,
                        15: 15%,
                        20: 20%,
                        25: 25%,
                        30: 30%,
                        35: 35%,
                        40: 40%,
                        45: 45%,
                        50: 50%,
                        55: 55%,
                        60: 60%,
                        65: 65%,
                        70: 70%,
                        75: 75%,
                        80: 80%,
                        85: 85%,
                        90: 90%,
                        95: 95%,
                        100: 100%,
                        auto: auto
                )
        )
) !default;

$utilities: (
        "height": (
                property: height,
                class: h,
                values: (
                        1: 1%,
                        5: 5%,
                        10: 10%,
                        15: 15%,
                        20: 20%,
                        25: 25%,
                        30: 30%,
                        35: 35%,
                        40: 40%,
                        45: 45%,
                        50: 50%,
                        55: 55%,
                        60: 60%,
                        65: 65%,
                        70: 70%,
                        75: 75%,
                        80: 80%,
                        85: 85%,
                        90: 90%,
                        95: 95%,
                        100: 100%,
                        auto: auto
                )
        )
) !default;

@import '~bootstrap-slider/src/sass/bootstrap-slider';
@import '~@yaireo/tagify/src/tagify';
@import '~hm-icons/font/hm-icons.scss';
@import '~bootstrap/scss/bootstrap';
@import '~svgmap/src/scss/main.scss';


@import 'font';
@import 'layout';
@import 'player';
@import 'timeline';

.gradient-fill {
  background: rgb(102,53,53);
  background: linear-gradient(0deg, rgba(102,53,53,1) 0%, rgba(52,58,64,1) 66%);
}

.img-fluid-50 {
  max-width: 50%;
  height: auto;
}
